.MuiTableCell-head {
  text-align: center !important;
  font-family: "Poppins_SemiBold" !important;
  font-size: 12px !important;
  color: #1c1c1c !important;
  line-height: initial !important;
  border-bottom: none !important;
}

.MuiTableCell-body {
  text-align: center !important;
  font-family: "Poppins_SemiBold" !important;
  font-size: 12px !important;
  color: #1c1c1c !important;
  line-height: initial !important;
}

.MuiTableHead-root {
  background-color: #f0f0f0;
  border-radius: 5px !important;
}

/* .table-wrapper {
  background-color: white;
  margin-top: 1rem;
  border: 1px solid #e6e6e6;
  padding: 30px 20px 30px 20px;
  border-radius: 5px;
  margin-bottom: 1.5rem;
} */

.table-wrapper-pagination {
  background-color: white;
  margin-top: 1rem;
  border: 1px solid #e6e6e6;
  padding: 30px 20px 30px 20px;
  border-radius: 5px;
  margin-bottom: 1.5rem;
}

tbody > tr:last-child th {
  border-bottom: none !important;
}

thead > tr > th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

thead > tr > th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

tbody > tr:last-child td {
  border-bottom: none !important;
}

.table-wrapper-pagination {
  overflow: auto;
  white-space: nowrap;
}

.table-container-pagination {
  width: 100%;
  display: table;
  table-layout: fixed;
}

@media screen and (max-width: 992px) {
  .table-wrapper {
    overflow: auto;
    white-space: nowrap;
  }

  .table-container {
    width: 100%;
    display: table;
    table-layout: fixed;
  }
}

.action-btns > img {
  cursor: pointer;
}

.action-btns,
.communication-alert-cta {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.img-col,
.img-no-border {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  justify-content: center;
  text-decoration: none;
  color: #1c1c1c;
}

.img-col > img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  overflow-clip-margin: unset;
  overflow: hidden;
}

.img-no-border > img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  object-position: center;
  overflow-clip-margin: unset;
  overflow: hidden;
}

.img-col > p {
  flex: 1;
  text-align: left;
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  background-color: #005e9e !important;
  color: white !important;
  border-radius: 0px !important;
  font-family: "Poppins_SemiBold";
}

.css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root {
  border-radius: 0px !important;
  font-family: "Poppins_SemiBold" !important;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 3rem;
}

.action-btns > button {
  border: none;
  padding: 0;
  border-radius: 7px !important;
  margin-right: 10px;
  min-width: 34px;
  height: 34px;
}

.MuiTableCell-body {
  margin-bottom: -1px !important;
}

.actions-btns > button {
  margin-right: 10px;
  border: none;
  padding: 3px 15px !important;
  text-transform: capitalize !important;
  font-family: "Poppins_SemiBold";
  color: white;
  border-radius: 30px;
  cursor: pointer;
  /* height: 28px !important; */
}

.communication-alert-cta > button {
  margin-right: 10px;
  border: none;
  padding: 3px 15px !important;
  width: 34px !important;
  min-width: 34px !important;
  text-transform: capitalize !important;
  font-family: "Poppins_SemiBold";
  color: white;
  border-radius: 7px !important;
  cursor: pointer;
  height: 34px !important;
}

.pdf {
  text-decoration: none !important;
  color: white !important;
}

.actions-btns > a > button {
  margin-right: 10px;
  border: none;
  padding: 6px 20px;
  font-family: "Poppins_SemiBold";
  color: white;
  border-radius: 30px;
  cursor: pointer;
}

.not-assing-btn {
  background-color: #5c5c5c !important;
}

.assign-btn {
  background-color: #26a6fe !important;
}

.actions-btns > button:nth-child(2) {
  background-color: #46cc8d;
}

.actions-btns > button:nth-child(3) {
  background-color: #ff0000;
}

.action-btns > button:nth-child(1) {
  background-color: #46cc8d;
}

.communication-alert-cta > .CTA-1 {
  background-color: #ffb61b !important;
}

.communication-alert-cta > .CTA-2 {
  background-color: #46cc8d !important;
}

.communication-alert-cta > .CTA-3 {
  background-color: #26a6fe !important;
}

.action-btns > button:nth-child(2) {
  background-color: #26a6fe;
}

.action-btns button img {
  width: 20px;
}

.MuiTableContainer-root {
  overflow-y: hidden;
}

.gallery {
  background-color: #f7b940 !important;
}

.img-colss > a {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  text-decoration: none;
  color: #1c1c1c;
}

.img-colss > a > img {
  flex: 1;
}

.img-colss > a > p {
  flex: 1;
}

.img-colss {
  display: flex !important;
}

.user_img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background-color: transparent;
}

input[type="checkbox"] + label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  width: 15px;
  height: 15px;
  border: none;
  border-radius: 2px;
  background-color: #dcdcdc;
  cursor: pointer;
}

input[type="checkbox"] + label::before {
  content: "\2713";
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 10px;
  color: white;
  opacity: 0;
}

input[type="checkbox"]:checked + label {
  background-color: #005e9e;
}

input[type="checkbox"]:checked + label::before {
  opacity: 1;
}

.all-btns {
  display: flex !important;
  flex-direction: column !important;
}

.all-btns > button {
  margin-bottom: 5px;
  font-size: 12px !important;
}

.trash-icon {
  background-color: #ff0000 !important;
  min-width: 36px !important;
  width: 36px !important;
  height: 36px !important;
  border-radius: 7px !important;
}

.edit-icon {
  background-color: #46cc8d !important;
  min-width: 36px !important;
  width: 36px !important;
  height: 36px !important;
  border-radius: 7px !important;
}

.repeat-icon {
  background-color: #26a6fe !important;
  min-width: 34px !important;
  width: 34px !important;
  height: 34px !important;
  border-radius: 7px !important;
}

.reverse-cta {
  background-color: #46cc8d !important;
  color: white !important;
  font-size: 12px !important;
  border-radius: 7px !important;
  padding: 6px 18px !important;
  font-family: "Poppins_SemiBold" !important;
  text-transform: capitalize !important;
}

.product_pic {
  width: 42px;
  height: 56px;
}

.vacant-icon {
  width: 28px;
}

.assign-secondary {
  text-transform: capitalize !important;
  background-color: #26a6fe !important;
  color: white !important;
  font-size: 12px !important;
  font-family: "Poppins_SemiBold" !important;
  padding: 6px 10px !important;
  border-radius: 20px !important;
  height: 24px;
}

.Reassign-secondary {
  text-transform: capitalize !important;
  background-color: #5c5c5c !important;
  color: white !important;
  font-size: 12px !important;
  font-family: "Poppins_SemiBold" !important;
  padding: 6px 10px !important;
  border-radius: 20px !important;
  height: 24px;
}

.image-commu {
  position: relative;
  top: 0;
}

.image-commu > span > div {
  background-color: rgba(255, 0, 0, 1);
  position: absolute;
  top: -7px;
  right: -7px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  font-size: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "Poppins_SemiBold";
  color: white;
}

.picklist-status {
  width: 130px !important;
}

.pick-comp > div > div > .css-1jqq78o-placeholder {
  color: #1a5d1a !important;
}

.pick-act > div > div > .css-1jqq78o-placeholder {
  color: #1b6b93 !important;
}

.pick-hold > div > div > .css-1jqq78o-placeholder {
  color: #df2e38 !important;
}

.border-before {
  display: inline-block;
  border-bottom: 1px solid #000;
}

.download-pdf-cta {
  background-color: #46cc8d !important;
}
.cancel-cta {
  background-color: #ff0000 !important;
}

.MuiButton-root.Mui-disabled {
  color: #000 !important;
}

.action-btn-lost-req {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.action-btn-lost-req > button,
.mark-lost-cta,
.action-btn-lost-req > a > button {
  height: 38px;
  margin-bottom: 5px;
  font-size: 12px !important;
  width: 120px;
  border-radius: 0px !important;
  text-transform: capitalize !important;
  font-family: "Poppins_SemiBold" !important;
  color: white !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.action-btn-lost-req > button:nth-child(1),
.mark-lost-cta {
  background-color: rgba(55, 185, 156, 1) !important;
}

.action-btn-lost-req > a > button {
  background-color: rgba(38, 166, 254, 1) !important;
}

.viewBtn {
  border: none;
  box-shadow: none;
  background-color: rgba(55, 185, 156, 1) !important;
  color: #ffffff !important;
}
.viewBtn:disabled {
  opacity: 0.6;
  pointer-events: none !important;
}
.viewBtn:focus {
  outline: none;
}
.openSession {
  background-color: rgba(38, 166, 254, 1) !important;
}
.closeSession {
  background-color: rgb(226, 74, 27) !important;
}
.sessionClosed {
  color: rgb(226, 74, 27) !important;
}

.table-input {
  border: 1px solid #dcdcdc;
  /* padding: 0.6rem 0.8rem; */
  border-radius: 0.25rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #202020;
  font-family: "Poppins_Medium";
  font-size: 14px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.table-input:focus {
  outline: none;
}

.break-wrap-word {
  width: 200px;
  white-space: normal;
  text-decoration: none;
  color: #1c1c1c;
  text-align: center;
  margin: auto;
}

.break-wrap-wordX {
  width: 300px;
  white-space: normal;
  text-decoration: none;
  color: #1c1c1c;
  text-align: center;
  margin: auto;
  display: flex;
}

.break-word-user {
  width: 120px;
  white-space: normal;
  text-decoration: none;
  color: #1c1c1c;
  text-align: center;
  margin: auto;
}

.break-word-user > .img-col {
  display: grid !important;
  grid-template-columns: 0.7fr 1fr !important;
}

@media screen and (max-width: 598px) {
  .pagination {
    flex-direction: column;
  }
}

.row-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #1c1c1c;
  text-align: center;
}

.row-col > img {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.row-col > p {
  margin-top: 5px;
}
