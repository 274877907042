.portal-management {
    font-family: 'Poppins_SemiBold';
    font-size: 30px;
    color: #1C1C1C;
}

@media screen and (max-width: 576px) {
    .portal-management {
        font-size: 26px;
    }
}

.d-flex-date-selector,
.d-flex-date-selectors {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;
}


@media screen and (max-width: 576px) {
    .d-flex-date-selector {
        flex-direction: column;
    }

    .d-flex-date-selectors {
        align-items: baseline;
    }
}