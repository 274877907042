.labelBtn {
  border: none;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: 500;
  padding: 0.6rem 1.2rem;
  color: white;
  background-color: #37b99c;
  cursor: pointer;
}

.labelBtn:focus {
  outline: none;
}
