.image-container {
  width: 100%;
  height: 400px;
  overflow-y: hidden;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  /* object-fit: contain; */
}

.awssld__controls__arrow-left:after,
.awssld__controls__arrow-left:before {
  background-color: #005e9e !important;
}

.awssld__controls__arrow-right:before,
.awssld__controls__arrow-right::after {
  background-color: #005e9e !important;
}

.awssld__bullets {
  bottom: 15px !important;
}

.slider-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 80vh;
  height: 70vh;
  margin: 0 auto;
  position: relative;
  top: 0;
}

.slider-image-container > img {
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
}

.awssld__content{
  background-color: #F7F7F7 !important;
}

.awssld__bullets .awssld__bullets--active{
  background-color: #005e9e !important;
}

.is-image-loaded{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}