.modal-display {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-box {
    background-color: white;
    padding: 20px 20px 20px 20px;
    max-width: 300px;
    width: 300px;
    margin: auto;
    border-radius: 5px;
}

#modal-modal-title {
    font-family: 'Poppins_SemiBold';
    font-size: 22px;
    color: #1C1C1C;
}

.modal-grid-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.cross-icon {
    background-color: #1C1C1C;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    cursor: pointer;
}


.input-field>label {
    font-family: 'Poppins_Bold';
    color: #1C1C1C;
    font-size: 14px;
}

.input-field>input {
    border: 1px solid #E6E6E6;
    height: 48px;
    border-radius: 5px;
    width: 100%;
    margin-top: 5px;
    outline: none;
    padding: 0px 10px 0px 10px;
    font-family: 'Poppins_SemiBold';
    font-size: 14px;
}

.mt{
    margin-top: 1rem;
}

.input-field{
    margin-bottom: 1rem;
}