.header-wrapper {
  background: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: fixed;
  top: 0;
  max-width: 100%;
  width: 100%;
  z-index: 88;
  right: 0;
  padding-left: 250px;
}

.header-full {
  padding-left: 0px;
}

.header-grid-row {
  display: grid;
  grid-template-columns: 4fr 2fr;
  gap: 2rem;
}

.header-grid-row > div {
  height: auto;
  padding: 10px 40px 10px 60px;
}

.header-grid-row > div:nth-child(2) {
  display: flex;
  align-items: center;
}

.header-grid-row > div:nth-child(2) > img {
  margin-right: 8px;
}

.search-area {
  position: relative;
  top: 0;
  max-width: fit-content;
}

.uri-header {
  /* position: absolute;
  top: -17px; */
  font-family: "Poppins_Medium_Italic";
  font-weight: bold;
  letter-spacing: 0.5px;
  font-size: 10px;
  background-color: #83ebba;
  color: black;
  padding: 6px 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.search-area > input {
  background: #f3f3f5;
  border-radius: 7px;
  border: none;
  width: 549px;
  outline: none;
  padding: 0px 15px 0px 15px;
  height: 48px;
  font-family: "Poppins_Medium";
  font-size: 14px;
  color: #1c1c1c;
  overflow-y: hidden;
}

::placeholder {
  color: #1c1c1c;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #1c1c1c;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #1c1c1c;
}

.search-btn {
  position: absolute;
  background: #26a6fe;
  border: none;
  cursor: pointer;
  height: 46px;
  top: 1px;
  right: 0;
  padding: 0px 20px 0px 20px;
  border-radius: 7px;
}

.avatar-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.avatar-flex-row > div:nth-child(1) {
  width: 40px;
  height: 40px;
  background-color: #26a6fe;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "Poppins_SemiBold";
  font-size: 16px;
  color: white;
}

.avatar-flex-row > div > p {
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  color: #1c1c1c;
  margin-top: 2px;
}

.avatar-flex-row > div > button {
  font-family: "Poppins_Medium";
  font-size: 13px;
  text-decoration: none;
  color: #d3571e;
  text-transform: uppercase;
  padding: 0 !important;
  margin-left: -4px !important;
  margin-top: -2px !important;
}

@media screen and (max-width: 1080px) {
  .header-wrapper {
    width: 100%;
  }
  .header-wrapper {
    margin-left: 0px;
  }
}

/* @media screen and (min-width: 1500px) {
  .header-wrapper {
    margin-left: -30px;
    width: 88%;
  }
} */

@media screen and (max-width: 992px) {
  .header-grid-row {
    grid-template-columns: repeat(1, 1fr);
  }

  .header-grid-row > div:nth-child(1) {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .search-area > input {
    width: 450px;
  }

  .header-grid-row > div {
    padding: 20px 10px 20px 10px;
  }
}

@media screen and (max-width: 576px) {
  .search-area > input {
    width: 250px;
  }

  .search-btn {
    padding: 0px 10px 0px 10px;
  }
}

@media screen and (max-width: 1080px) {
  .header-wrapper {
    padding-left: 0px;
  }
}

.menu-item {
  white-space: nowrap;
}

.wrapper-picker-container {
  max-width: 98%;
  margin: 6rem auto;
}

@media screen and (min-width: 1536px) {
  .wrapper-container {
    max-width: 1440px;
    margin: auto;
  }

  .menu-item {
    background-size: 232% 100%;
  }
}

.header-grid-row > div:nth-child(2) {
  gap: 10px;
}

@media screen and (min-width: 1100px) {
  .header-grid-row > div:nth-child(2) {
    justify-content: center;
    /* gap: 20px; */
  }
}

.count-aler-c {
  position: relative;
  top: 0;
  cursor: pointer;
}

.count-aler-c > div {
  width: 18px;
  height: 18px;
  background-color: #ff0000;
  position: absolute;
  top: -4px;
  right: -1px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.count-aler-c > div > p {
  font-family: "Poppins_SemiBold";
  font-size: 10px;
  color: white;
}

.count-aler-c > svg {
  color: #f8de22;
  font-size: 2rem;
}

.link-icon {
  font-size: 1.6rem !important;
  color: white;
  cursor: pointer;
}
