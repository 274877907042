.grid-delivery-partner-info {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
}

.grid-delivery-partner-info > div {
  height: auto;
}

.save-delivery-cta {
  background-color: #46cc8d !important;
  color: white !important;
  font-size: 15px;
  font-family: "Poppins_SemiBold" !important;
  border-radius: 8px !important;
  height: 58px;
  width: 124px;
  /* padding: 10px 30px !important; */
  text-transform: capitalize !important;
}

.delivery-cta {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

@media screen and (max-width: 640px) {
  .grid-delivery-partner-info {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-delivery-partner-info > div > label {
  font-size: 14px;
  color: #6c6c6c;
  font-family: "Poppins_SemiBold";
}

.button-label-grid {
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.inventory > textarea {
  width: 100%;
  margin-top: 10px;
  outline: none;
  padding: 10px 10px 10px 10px;
  font-size: 14px;
  font-family: "Poppins_SemiBold";
  border-radius: 5px;
  border: 1px solid #e6e6e6;
  margin-bottom: 15px;
  resize: vertical;
}

.button-label-grid > button:nth-child(1) {
  background-color: #fa3e3e !important;
}

.button-label-grid > button:nth-child(2) {
  background-color: #46cc8d !important;
  margin-left: 20px;
}

.button-label-grid > button {
  text-transform: capitalize !important;
  color: white !important;
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  height: 48px;
  padding: 0px 20px 0px 20px;
  border-radius: 5px !important;
  margin-top: 20px;
}

@media screen and (max-width: 640px) {
  .mt-56 {
    margin-top: 15px !important;
  }
}

.inventory {
  position: relative;
  top: 0;
}

.background-white-list {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: absolute;
  top: 100px;
  background-color: white;
  height: 150px;
  overflow-y: auto;
  padding: 20px 10px 20px 10px;
  border-radius: 5px;
  width: 100%;
  text-align: center;
}

.background-white-list > div {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}

.background-white-list > div > p {
  font-family: "Poppins_Regular";
  font-size: 14px;
  border-bottom: 1px solid #f7f7f7;
  padding-bottom: 10px;
}

.no-search {
  font-family: "Poppins_Bold";
  font-size: 16px;
  color: #1c1c1c;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.background-white-list::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.background-white-list {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.label-bag > button {
  width: auto;
  text-transform: capitalize;
  color: #1c1c1c;
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  height: 40px;
  border-radius: 5px !important;
  background-color: rgba(38, 166, 254, 1) !important;
  border-radius: 5px !important;
  min-width: 100% !important;
  width: 100%;
}

.list-inventory {
  height: 100px !important;
  overflow: auto;
}

.list-inventory > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  background-color: #f7f7f7;
  margin-bottom: 10px;
  padding: 5px 10px 5px 10px;
  border-radius: 5px;
}

.list-inventory > div > p {
  font-family: "Poppins_Regular";
  font-size: 14px;
}

.list-inventory > div > button {
  width: 20px !important;
  min-width: 20px !important;
  height: 20px !important;
}

.list-inventory > div > svg {
  font-size: 18px;
}

.list-inventory::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.list-inventory::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

.list-inventory::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 1px solid #555555;
}

.tab-module-flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.please-wait > p {
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  color: #555555;
}

.please-wait{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 35px;
  margin-right: 40px;
}
