.by-pass-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
}

.by-pass-fluid {
  background-color: white;
  height: auto;
  width: 400px;
  padding: 30px 20px 30px 20px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 2px;
}

.by-pass-fluid > label {
  font-family: "Poppins_Bold";
  color: #1c1c1c;
  font-size: 14px;
}

.by-pass-fluid > input {
  border: 1px solid #e6e6e6;
  height: 48px;
  border-radius: 5px;
  width: 100%;
  margin-top: 5px;
  outline: none;
  padding: 0px 10px 0px 10px;
  font-family: "Poppins_SemiBold";
  font-size: 14px;
}

.add-remove-cta-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.add-remove-cta-container > button:nth-child(1) {
  background-color: #557c55 !important;
  color: white;
  /* width: 40px !important; */
  padding: 0px 20px 0px 20px !important;
  height: 40px !important;
  border-radius: 0px !important;
  font-family: "Poppins_Bold";
  width: 140px !important;
}

.add-remove-cta-container > button:nth-child(2) {
  background-color: #fa7070 !important;
  color: white;
  /* width: 40px !important; */
  height: 40px !important;
  border-radius: 0px !important;
  padding: 0px 20px 0px 20px !important;
  font-family: "Poppins_Bold";
  width: 140px !important;
}

@media screen and (max-width: 576px) {
  .by-pass-fluid {
    width: 100%;
    margin: 0px 10px 0px 10px;
  }
  .add-remove-cta-container > button:nth-child(1),
  .add-remove-cta-container > button:nth-child(2) {
    width: 122px !important;
  }
}

/* ByPass Order Mapping css starts here */
.scanning-input-row {
  max-width: 300px;
  margin-top: 10px;
  margin-bottom: 30px;
}

.scanning-input-container {
  position: relative;
  top: 0;
  margin-top: 8px;
}

.scanning-input-row > label {
  color: rgba(108, 108, 108, 1);
  font-size: 14px;
  font-family: "Poppins_Medium";
}

.scanning-input-container > input {
  position: relative;
  top: 0;
  border: 1px solid rgba(230, 230, 230, 1);
  height: 48px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  width: 100%;
}

.scanning-input-container > img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  width: 24px;
  height: 24px;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.bypass-order-mapping-header {
  font-weight: 600;
  color: rgba(28, 28, 28, 1);
  font-size: 26px;
  font-family: "Poppins_SemiBold";
}

.bypass-order-mapping-cta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.bypass-order-mapping-cta > button {
  background-color: rgba(55, 185, 156, 1) !important;
  width: 125px;
  height: 48px;
  font-family: "Poppins_SemiBold";
  color: white;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

@media screen and (max-width: 598px) {
  .bypass-order-mapping-header {
    font-size: 22px;
  }
}
/* ByPass Order Mapping css ends here */
