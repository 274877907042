.logo {
  text-align: center;
}

.logo > img {
  margin-top: 1rem;
  width: 150px;
}

.sidebar-menu {
  height: 100vh;
  background-color: #005e9e;
  position: fixed;
  overflow-y: scroll;
  transition: transform 0.5s ease-in-out;
  transform: translateX(0);
  z-index: 666;
  width: 263px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.sidebar-menu::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.sidebar-menu {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.menu-item {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
  color: white;
  font-family: "Poppins_Medium";
  margin: 0.5rem;
  padding: 0.6rem;
  gap: 10px;
  font-size: 0.875rem;
  border-radius: 0.2rem;
  background: linear-gradient(
    to left,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%
  );
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease;
  position: relative;
  top: 0;
  outline: none;
  border: none;
  -webkit-border-radius: 0.2rem;
  -moz-border-radius: 0.2rem;
  -ms-border-radius: 0.2rem;
  -o-border-radius: 0.2rem;
}

.menu-item img {
  width: 1.35rem;
}

.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.menu-item:hover {
  background-position: left bottom;
}

.routes {
  /* margin: 10px 15px 10px 15px; */
  margin-top: 2rem;
}

.menu-wrapper::after {
  content: "";
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 70%;
  display: block;
  margin: 0 auto;
}

.sub-wrapper::after {
  content: "";
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  width: 70%;
  display: block;
  margin: 0 auto;
}

/* mobile responsive */
.visible {
  transform: translateX(0);
}

.hiddens {
  transform: translateX(-310px);
}

.menu-wrapper {
  position: relative;
  top: 0;
}

.menu-wrapper > svg {
  position: absolute;
  top: 12px;
  right: 25px;
  color: white;
  cursor: pointer;
  margin-right: -8px;
}

.animate {
  animation-name: slideIn;
  animation-duration: 1s;
  transition: 0.5s ease-in-out;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(-100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.sub-routes {
  margin-left: 1.25rem;
  padding: 0.6rem;
  border-radius: 0.5rem;
  gap: 0.5rem;
}

.counts-alert {
  width: 22px;
  height: 22px;
  background-color: #e43434;
  border: 2px solid #ffffff;
  /* position: absolute;
  top: -15px;
  right: -10px; */
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.7rem;
  font-weight: 500;
  line-height: 0px;
}

@media screen and (max-width: 992px) {
  .menu-item {
    background-size: 210% 100%;
  }
}
