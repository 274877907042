.header-limit-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.lable-select-all {
  font-family: "Poppins_Regular";
  font-size: 12px;
  color: #1c1c1c;
}

.header-limit-checkbox > div:nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.create-picklist-requirements {
  text-transform: capitalize !important;
  background-color: #26a6fe !important;
  color: white !important;
  font-size: 12px !important;
  font-family: "Poppins_SemiBold" !important;
  /*  */
  padding: 6px 10px !important;
  border-radius: 20px !important;
  height: 24px;
}

.re-calculate-order {
  text-transform: capitalize !important;
  background-color: #ff0000 !important;
  color: white !important;
  font-size: 12px !important;
  font-family: "Poppins_SemiBold" !important;
  /*  */
  padding: 6px 10px !important;
  border-radius: 20px !important;
  height: 24px;
  width: 100px;
}

.date-picker {
  border: none;
  min-height: 48px;
  border-radius: 4px;
  padding: 2px 8px;
  font-family: Poppins_SemiBold;
  font-size: 12px;
  outline: none;
  background-color: #e0e0e0;
  width: 100%;
}

.limit > input {
  border: none;
  border-radius: 23px;
  background: #e0e0e0;
  width: 60px !important;
  height: 28px !important;
  border: none;
  outline: none;
  padding: 0px 10px 0px 10px;
  font-family: "Poppins_SemiBold";
  position: relative;
  top: 0;
  font-size: 14px;
  text-align: center;
}

.limit {
  position: relative;
  top: 0;
  text-align: center;
}

.result-flex-grid {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
