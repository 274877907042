.mt-3 {
  margin-top: 1.5rem;
}

.btn-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.para {
  margin-top: 0.3rem;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: "Poppins_medium";
  color: #1c1c1c;
}

.box-info {
  padding: 0 4rem;
}

@media screen and (max-width: 600px) {
  .box-info {
    padding: 0;
  }
}

.mb-3 {
  margin-bottom: 1.5rem;
}
