.input-field-delivery > input {
  width: 100%;
  border: 1px solid #e6e6e6;
  height: 48px;
  outline: none;
  padding: 0px 10px 0px 10px;
  font-size: 14px;
  font-family: "Poppins_SemiBold";
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.textarea > textarea {
  width: 100%;
  margin-top: 10px;
  outline: none;
  padding: 10px 10px 10px 10px;
  font-size: 14px;
  font-family: "Poppins_SemiBold";
  border-radius: 5px;
  border: 1px solid #e6e6e6;
}

.input-field-delivery > label,
.textarea > label {
  font-size: 14px;
  color: #6c6c6c;
  font-family: "Poppins_SemiBold";
}

.date > input {
  width: 100%;
  border: 1px solid #e6e6e6;
  height: 48px;
  outline: none;
  padding: 0px 10px 0px 10px;
  font-size: 14px;
  font-family: "Poppins_SemiBold";
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.bg-dark {
  background-color: #f0f0f0;
}

.errorpara {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.65rem;
  font-family: "Poppins_medium";
  color: #ff0000;
}
