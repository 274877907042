.create-reverse-handover {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.date > label {
  font-size: 14px;
  color: #6c6c6c;
  font-family: "Poppins_SemiBold";
}

@media screen and (max-width: 992px) {
  .create-reverse-handover {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .create-reverse-handover {
    grid-template-columns: repeat(1, 1fr);
  }
}

.images-grid-reversal-order {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: fit-content;
}

.images-grid-reversal-order > div {
  height: auto;
}

.images-grid-reversal-order > div > p {
  font-size: 14px;
  color: #1c1c1c;
  font-family: "Poppins_Regular";
}

.images-grid-reversal-order > div > img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.new-order-awb-no-data {
  margin-top: 30px;
  background-color: #ffffff;
  border: 1px solid #e6e6e6;
  height: auto;
  border-radius: 5px;
  padding: 20px 30px 10px 30px;
}
