.SKU-container {
  margin: 20px auto 0px auto;
}

.sku-img {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}

.sku-img > img {
  width: 132px;
  height: 176px;
  object-fit: cover;
  object-position: top;
}

.SKU-container > h3 {
  font-family: "Poppins_SemiBold";
  margin-top: 10px;
  font-size: 16px;
  color: rgba(28, 28, 28, 1);
  text-align: center;
}

.SKU-container > p {
  font-family: "Poppins_Regular";
  font-size: 14px;
  margin-top: 10px;
  color: rgba(28, 28, 28, 1);
  text-align: center;
}

.sku-action-cta {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.sku-action-cta > div > button {
  color: white !important;
  font-size: 14px;
  font-family: "Poppins_Bold";
  height: 48px;
  width: 106px;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  -ms-border-radius: 2px !important;
  -o-border-radius: 2px !important;
}

.sku-action-cta > div:nth-child(1) > button {
  background-color: rgba(255, 0, 0, 1) !important;
}

.sku-action-cta > div:nth-child(2) > button {
  background-color: rgba(55, 185, 156, 1) !important;
}
