.container-bags {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.container-bags > button {
  width: auto;
  text-transform: capitalize;
  color: #1c1c1c;
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  height: 40px;
  border-radius: 5px !important;
}

.container-bags > button > span > img {
  width: 20px;
  height: 20px;
}

.container-bags > button:nth-child(1) {
  background-color: #46cc8d;
}

.container-bags > button:nth-child(2) {
  background-color: #fa3e3e;
}

.container-bags > button:nth-child(3) {
  background-color: #26a6fe;
}

.container-bags > button:nth-child(4) {
  background-color: #ffb61b;
}

.createNewBtn {
  background-color: #005e9e !important;
  padding: 7px 20px !important;
  height: 38px !important;
  font-family: "Poppins_SemiBold" !important;
  color: white !important;
  border: none !important;
  border-radius: 30px !important;
  cursor: pointer !important;
  text-transform: capitalize !important;
  display: flex !important;
  gap: 0.25rem !important;
}

.close-reversal-order {
  background-color: #fa3e3e !important;
  height: 40px !important;
  color: white !important;
  border-radius: 5px !important;
  padding: 0px 20px 0px 20px;
  width: 230px !important;
  font-family: "Poppins_SemiBold" !important;
  text-transform: capitalize !important;
}

.order-reverals-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .order-reverals-header {
    flex-direction: column;
    align-items: flex-start;
  }
}

.side-by-label {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  justify-content: space-between;
}

.side-by-label > div > button,
.bags-into-col > div > button {
  background-color: #46cc8d !important;
  padding: 7px 20px;
  font-family: "Poppins_SemiBold";
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  text-transform: capitalize;
  height: 38px;
}

.side-by-label > div > .btn-sec {
  background-color: #26a6fe !important;
}

.side-by-label > div > h1 {
  color: #1c1c1c;
  font-family: "Poppins_SemiBold";
  font-size: 30px;
}

@media screen and (max-width: 576px) {
  .side-by-label > div > h1 {
    font-size: 20px;
  }
}

@media screen and (max-width: 992px) {
  .side-by-label {
    flex-direction: column;
  }
}
