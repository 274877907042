.go-back-row {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.wrapper-picker-container {
  margin-top: 4.5rem;
  padding: 20px 30px 20px 30px;
  position: relative;
  top: 0;
}

.back-icon-cta,
.forward-icon-cta {
  background-color: #26a6fe !important;
  min-width: 20px !important;
  border-radius: 20px 0px 0px 20px !important;
  -webkit-border-radius: 20px 0px 0px 20px !important;
  -moz-border-radius: 20px 0px 0px 20px !important;
  -ms-border-radius: 20px 0px 0px 20px !important;
  -o-border-radius: 20px 0px 0px 20px !important;
}

.back-icon-cta > span > svg,
.forward-icon-cta > span > svg {
  font-size: 16px !important;
  color: white;
}

.back-icon-cta {
  position: fixed !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  right: -5px !important;
}

.forward-icon-cta {
  position: fixed !important;
  top: 55% !important;
  transform: translateY(-50%) !important;
  right: -5px !important;
  -webkit-transform: translateY(-50%) !important;
  -moz-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  -o-transform: translateY(-50%) !important;
}

.go-back-row {
  text-decoration: none;
}

.go-back-row > div > p {
  color: #1c1c1c;
  font-size: 30px;
  font-family: "Poppins_SemiBold";
}

.user-info {
  background-color: white;
  margin: 30px 0px 30px 0px;
  border: 1px solid #e6e6e6;
  padding: 30px 30px 30px 30px;
  border-radius: 5px;
}

.input-grid-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
}

.input-grid-row > div {
  height: auto;
}

.input-grid-row > div > input,
.input-grid-row > div > textarea {
  border-radius: 5px;
  height: 48px;
  background-color: #f0f0f0;
  border: none;
  width: 100%;
  outline: none;
  margin-top: 0.2rem;
  padding: 0px 10px 0px 10px;
  font-family: "Poppins_Bold";
  font-size: 15px;
}

.input-grid-row > div > textarea::-webkit-scrollbar {
  display: none;
}

.input-grid-row > div > label {
  font-family: "Poppins_SemiBold";
  color: #6c6c6c;
  font-size: 14px;
}

@media screen and (max-width: 640px) {
  .input-grid-row {
    grid-template-columns: repeat(2, 1fr);
  }

  .wrapper-picker-container {
    padding: 20px 10px 20px 10px;
  }
}

@media screen and (max-width: 576px) {
  .wrapper-picker-container > a > div > p {
    font-size: 22px;
  }

  .input-grid-row {
    grid-template-columns: repeat(1, 1fr);
  }

  .user-info {
    padding: 30px 15px 30px 15px;
  }
}

.media-action-grid-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 2rem;
  gap: 2rem;
}

.media-action-grid-row > button {
  height: auto;
  padding: 18px 10px 18px 10px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  width: 100%;
}

.media-action-grid-row > button > span > p {
  font-family: "Poppins_Bold";
  font-size: 15px;
  color: white;
  text-transform: capitalize;
}

@media screen and (max-width: 992px) {
  .media-action-grid-row {
    gap: 1rem;
  }
}

@media screen and (max-width: 640px) {
  .media-action-grid-row {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .media-action-grid-row > div {
    flex-direction: column;
  }
  .media-action-grid-row {
    grid-template-columns: repeat(1, 1fr);
  }
}

.headline-db {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.headline-db > div > p {
  font-family: "Poppins_SemiBold";
  font-size: 30px;
  color: #1c1c1c;
}

.headline-db > div:nth-child(2) > button {
  background-color: #46cc8d;
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  color: white;
  border: none;
  padding: 0px 20px;
  min-height: 40px;
  border-radius: 30px;
}

@media screen and (max-width: 640px) {
  .headline-db {
    flex-direction: column;
    align-items: unset;
  }
}

@media screen and (max-width: 576px) {
  .headline-db > div > p {
    font-size: 22px;
    margin-bottom: 1rem;
  }
}

.another-level-props-grid {
  display: grid;
  grid-template-columns: 1.94fr 4fr;
  grid-gap: 1rem;
  margin-top: 1rem;
}

.another-level-props-grid > div > input,
.another-level-props-grid > div > textarea {
  border-radius: 5px;
  height: 48px;
  background-color: #f0f0f0;
  border: none;
  width: 100%;
  outline: none;
  margin-top: 0.2rem;
  padding: 0px 10px 0px 10px;
  font-family: "Poppins_Bold";
  font-size: 15px;
}

.another-level-props-grid > div > textarea::-webkit-scrollbar {
  display: none;
}

.another-level-props-grid > div > label {
  font-family: "Poppins_SemiBold";
  color: #6c6c6c;
  font-size: 14px;
}

@media screen and (max-width: 576px) {
  .another-level-props-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}

.d-flex-select-date {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
}

@media screen and (max-width: 576px) {
  .d-flex-select-date {
    flex-direction: column;
  }
}

#currentSpeed {
  background-color: #46cc8d;
  color: white;
}

.picklist-details-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 576px) {
  .picklist-details-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .picklist-details-header {
    gap: 10px;
  }
  .picklist-details-header > div {
    align-self: flex-end;
  }
}
