.loader {
  border: 2px solid white;
  border-radius: 50%;
  border-top: 2px solid #5c5c5c;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

@import url("https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@1,700&display=swap");

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-alone {
  display: flex;
  justify-content: center;
  align-items: center;
  mix-blend-mode: multiply;
}

.loader-alone > img {
  width: 200px !important;
}

.no-categories > p {
  font-family: "Exo 2", sans-serif;
  font-size: 22px;
  position: relative;
  top: -30px;
  text-align: center;
}

.no-categories {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  mix-blend-mode: multiply;
}

.img-loader-modal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.loader-image-modal {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  border: 8px solid rgb(143, 200, 235);
  border-top-color: #26a6fe;
  animation: rotate 1s infinite;
}

@keyframes rotate {
  100% {
    rotate: 360deg;
  }
}
