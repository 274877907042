.login-screen-container {
  height: 100vh;
  background-image: url("../../Assets/Login-Bg/Group 2.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  position: relative;
  top: 0;
  align-items: center;
  background-position: center;
}

.login-inputs-wrapper-container {
  background-color: white;
  max-width: 400px;
  width: 400px;
  padding: 30px 20px 30px 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin: 0px 10px 0px 10px;
  position: relative;
  top: 0;
  border-bottom: 25px solid #005e9e;
}

.server-choose {
  position: absolute !important;
  top: -5px;
  left: -20px;
  min-width: 50px !important;
  width: 50px;
  height: 50px;
  background-color: #005e9e !important;
  border-radius: 50% !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.img-logo {
  text-align: center;
}

.img-logo > h1 {
  font-size: 32px;
  color: #000000;
  margin-top: 10px;
  font-family: "Playfair Display", serif;
  margin-bottom: 6px;
}

.img-logo > p {
  font-size: 14px;
  font-family: "Poppins_Regular";
  color: #1c1c1c;
  margin-top: -5px;
  margin-bottom: 15px;
}

.inputs-login-row > label {
  font-size: 14px;
  font-family: "Poppins_Regular";
  color: #1c1c1c;
}

.inputs-login-row {
  margin-bottom: 20px;
}

.inputs-login-row > input {
  width: 100%;
  height: 48px;
  background-color: #f0f0f0;
  border: none;
  outline: none;
  padding: 0px 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  margin-top: 5px;
  color: #1c1c1c;
  font-family: "Poppins_SemiBold";
  font-size: 14px;
}

.login-submit-btn > button {
  background-color: #005e9e !important;
  height: 48px;
  color: white;
  font-family: "Poppins_Bold";
  font-size: 14px;
  border: none;
  width: 225px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
}

.login-submit-btn {
  text-align: center;
  margin-top: 30px;
}

.relative-pass {
  position: relative;
  top: 0;
}

.show-hide-password {
  position: absolute;
  top: 32px;
  right: 5px;
}

.download-qz-tray{
  margin-top: 20px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  text-decoration: none;
}

.download-qz-tray>button{
  background-color: rgba(0, 0, 0, 0.04);
}

.download-qz-tray>button>span>p{
  font-family: 'Poppins_SemiBold' !important;
  font-size: 14px !important;
  color: #005e9e;
}

.download-qz-tray>button>span>svg{
  color: #005e9e;
}

.uri{
  font-family: 'Poppins_Regular';
  font-size: 12px;
  /* margin-top: 20px; */
  position: absolute;
  bottom: -22px;
  color: white;
  left: 20px;
}

@media screen and (max-width: 576px) {
  .uri{
    font-size: 10px;
  }
}