.Tab-module {
  margin-top: 2rem;
}

.tab-module-grid-row {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  max-width: fit-content !important;
  margin-left: 5px !important;
  gap: 0.25rem !important;
}

.update-tab-module-grid-row {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  max-width: fit-content !important;
  margin-left: 5px !important;
  gap: 0.25rem !important;
}

.update-tab-module-grid-row-2 {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  max-width: fit-content !important;
  margin-left: 5px !important;
  gap: 0.25rem !important;
}

@media screen and (min-width: 900px) {
  .update-tab-module-grid-row-2 {
    grid-template-columns: repeat(7, 1fr);
  }
}

@media screen and (min-width: 700px) {
  .update-tab-module-grid-row {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 900px) {
  .update-tab-module-grid-row {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (min-width: 1100px) {
  .update-tab-module-grid-row {
    grid-template-columns: repeat(9, 1fr);
  }
}

@media screen and (min-width: 1250px) {
  .update-tab-module-grid-row {
    grid-template-columns: repeat(11, 1fr);
  }
}

.overflow-hidden {
  overflow: hidden;
}
.overflow-auto {
  overflow: auto;
}

.tab-module-grid-row > a {
  padding: 0.95rem 1rem !important;
  border-top-left-radius: 10px;
  cursor: pointer;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
}

.tab-module-grid-row > a > p {
  font-family: "Poppins_SemiBold";
  font-size: 0.89rem;
  color: white;
}

.tab-module-grid-row > div {
  padding: 0.75rem 1rem;
  border-top-left-radius: 10px;
  cursor: pointer;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
}

.tab-module-grid-row > div > p {
  font-family: "Poppins_SemiBold";
  font-size: 0.8rem;
  color: white;
}

.activeTab {
  background-color: #005e9e;
  pointer-events: none;
}

.inActiveTab {
  background-color: #a0a0a0;
  pointer-events: none;
}

.lactiveTab {
  background-color: #005e9e;
}

.linActiveTab {
  background-color: #a0a0a0;
}

.summary-box-grid-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  max-width: fit-content;
}

.summary-box-grid-row > div > input {
  border-radius: 5px;
  height: 48px;
  background-color: #f0f0f0;
  border: none;
  width: 100%;
  outline: none;
  margin-top: 0.2rem;
  padding: 0px 10px 0px 10px;
  font-family: "Poppins_Bold";
  font-size: 15px;
}

.summary-box-grid-row > div > label {
  font-family: "Poppins_SemiBold";
  color: #6c6c6c;
  font-size: 14px;
}

.summary-box {
  background-color: white;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  padding: 30px 30px 30px 30px;
}

#invoice-pdf {
  background-color: #f8f7f7;
  max-width: 400px;
  margin: 2rem auto;
  padding: 10px 20px 10px 20px;
  border-radius: 5px;
}

.headline-invoice {
  font-family: "Poppins_Bold";
  font-size: 16px;
  color: #1c1c1c;
  margin-bottom: 10px;
}

.invoice-label-grid-row > div:nth-child(odd) > p {
  font-family: "Poppins_Regular";
  color: #1c1c1c;
  font-size: 12px;
}

.sku-grid-row > div > p {
  font-family: "Poppins_SemiBold";
  color: #1c1c1c;
  font-size: 12px;
  text-align: center;
}

.sku-grid-row-two > div > p {
  font-family: "Poppins_SemiBold";
  color: #1c1c1c;
  font-size: 12px;
  text-align: center;
}

.head-f {
  font-family: "Poppins_Regular" !important;
  color: #1c1c1c;
  font-size: 12px;
  text-align: center;
}

.invoice-label-grid-row > div:nth-child(even) > p {
  font-family: "Poppins_SemiBold";
  color: #1c1c1c;
  font-size: 12px;
}

.invoice-label-grid-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.invoice-label-grid-row > div {
  border: 0.5px solid #000000;
  padding: 10px 10px 10px 10px;
}

.invoice-label-grid-row > div:nth-child(1),
.invoice-label-grid-row > div:nth-child(2) {
  border: 0.5px solid #000000 !important;
}

.invoice-label-grid-row > div:nth-child(odd) {
  border-right: none !important;
}

.invoice-label-grid-row > div:nth-child(odd),
.invoice-label-grid-row > div:nth-child(even) {
  border-top: none;
}

.mt-8 {
  margin-top: 15px;
}

.sku-grid-row {
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
}

.sku-grid-row-two {
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr 0.5fr;
}

.sku-grid-row > div {
  border: 0.5px solid #000000;
  padding: 10px 10px 10px 10px;
  border-bottom: none;
}

.sku-grid-row > div:nth-child(1),
.sku-grid-row > div:nth-child(2),
.sku-grid-row > div:nth-child(4),
.sku-grid-row > div:nth-child(5),
.sku-grid-row > div:nth-child(7),
.sku-grid-row > div:nth-child(8) {
  border-right: none;
}

.sku-grid-row > div:nth-child(7),
.sku-grid-row > div:nth-child(8),
.sku-grid-row > div:nth-child(9) {
  border-bottom: 0.5px solid #000000;
}

.sku-grid-row-two > div {
  border: 0.5px solid #000000;
  padding: 10px 10px 10px 10px;
  border-bottom: none;
}

.sku-grid-row-two > div:nth-child(1),
.sku-grid-row-two > div:nth-child(2),
.sku-grid-row-two > div:nth-child(3),
.sku-grid-row-two > div:nth-child(5),
.sku-grid-row-two > div:nth-child(6),
.sku-grid-row-two > div:nth-child(7),
.sku-grid-row-two > div:nth-child(9),
.sku-grid-row-two > div:nth-child(10),
.sku-grid-row-two > div:nth-child(11) {
  border-right: none;
}

.sku-grid-row-two > div:nth-child(9),
.sku-grid-row-two > div:nth-child(10),
.sku-grid-row-two > div:nth-child(11),
.sku-grid-row-two > div:nth-child(12) {
  border-bottom: 0.5px solid #000000;
}

.invoice-download {
  background-color: #46cc8d;
  height: 50px;
  border: none;
  padding: 0px 26px 0px 26px;
  color: white;
  font-family: "Poppins_SemiBold";
  border-radius: 8px;
  font-size: 15px;
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.download-invoice-lable-cta {
  text-align: center;
  margin-top: 20px;
}

.download-invoice-lable-cta > button {
  font-family: "Poppins_SemiBold";
  font-size: 15px;
  text-transform: capitalize !important;
  background-color: #46cc8d !important;
  color: white !important;
  height: 58px !important;
  border-radius: 8px !important;
  padding: 20px 30px 20px 30px;
}

.printers-available {
  margin-top: 20px;
}

.printer-cta {
  text-align: center;
  margin-top: 15px;
}

.printer-cta > button {
  background-color: #46cc8d !important;
  color: white;
  border-radius: 2px !important;
  font-family: "Poppins_SemiBold" !important;
  padding: 12px 20px;
  text-transform: capitalize !important;
}

.manifest-cta > button {
  height: 48px !important;
  text-transform: capitalize !important;
  color: white !important;
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  height: 48px;
  padding: 0px 20px 0px 20px;
  border-radius: 5px !important;
  margin-top: 20px;
  background-color: #46cc8d !important;
}

.invoice-download-tote {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.invoice-download-tote > div:nth-child(2) > button {
  background-color: #ff0000;
  height: 58px;
  border-radius: 8px !important;
  font-family: "Poppins_SemiBold";
  font-size: 15px;
  color: white;
  text-transform: capitalize !important;
  padding: 20px 30px 20px 30px;
  width: 182px;
}

.invoice-download-tote > div:nth-child(2) {
  margin-top: 20px;
}

@media screen and (max-width: 576px) {
  .invoice-download-tote {
    flex-direction: column;
  }
  .invoice-download-tote > div:nth-child(2) {
    margin-top: 0px;
  }
  .download-invoice-lable-cta > button {
    line-height: 1.295;
  }
}
