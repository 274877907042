.audio-btn {
  background: radial-gradient(50% 50% at 50% 50%, #14ff00 0%, #0fbc00 99.99%);
  width: 82px;
  height: 82px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0px 20px 0px;
}

.audio > h3 {
  font-size: 20px;
  color: #1c1c1c;
  text-transform: uppercase;
  font-family: "Poppins_SemiBold";
}
