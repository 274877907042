.grid-sidebar-row {
  display: grid;
  grid-template-columns: 263px 4fr;
}

.active-grid-sidebar-row {
  grid-template-columns: 1fr;
}

.grid-sidebar-row > div {
  height: auto;
}

.menu-icon {
  position: fixed;
  right: 20px;
  top: 20px;
  font-size: 1.25rem;
  cursor: pointer;
  display: block;
  z-index: 999;
  color: #3b4042;
}

@media screen and (max-width: 1080px) {
  .menu-icon {
    display: block;
  }

  .grid-sidebar-row {
    grid-template-columns: repeat(1, 1fr);
  }
}

.wrapper-container {
  padding: 20px 30px 20px 30px;
}

.ind-d-flex > h1 {
  font-family: "Poppins_SemiBold";
  color: #1c1c1c;
  font-size: 30px;
}

.main-home-container > h2 {
  font-family: "Poppins_SemiBold";
  margin: 20px 0px 20px 0px;
  color: #1c1c1c;
  font-size: 30px;
}

/* operation metrics */
.grid-opertional-row {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  margin-top: 1.5rem;
}

.metric-grid-operation-row {
  grid-template-columns: repeat(5, 1fr);
}

.grid-opertional-row > div {
  padding: 30px 20px 30px 20px;
  border-radius: 5px;
}

.grid-opertional-row > div > p:nth-child(1) {
  font-family: "Poppins_Bold";
  font-size: 22px;
  margin-bottom: 0.2rem;
}

.grid-opertional-row > div > p:nth-child(2) {
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  text-transform: capitalize;
}

@media screen and (max-width: 992px) {
  .grid-opertional-row {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .grid-opertional-row {
    grid-template-columns: repeat(2, 1fr);
  }

  .wrapper-container {
    padding: 20px 10px 20px 10px;
  }

  .grid-opertional-row > div > p:nth-child(1) {
    font-size: 18px;
  }

  .grid-opertional-row > div > p:nth-child(2) {
    font-size: 12px;
  }

  .wrapper-container > h1,
  .wrapper-container > h2 {
    font-size: 26px;
  }
}

/* @media screen and (min-width: 1600px) {
  .grid-sidebar-row,
  .header-wrapper {
    max-width: 1440px !important;
    margin: auto;
  }
} */

/* doughnut chart js css starts here */
.doughnut-chart-grid-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 2.5rem;
  grid-gap: 2rem;
}

.doughnut-chart-grid-row > div {
  height: auto;
  padding: 20px 20px 20px 20px;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
}

.doughnut-chart-grid-row > div > p {
  font-family: "Poppins_Regular";
  font-size: 16px;
  color: #9291a5;
  margin-bottom: -0.3rem;
}

.doughnut-chart-grid-row > div > h3 {
  font-family: "Poppins_Bold";
  color: #1f1f1f;
  font-size: 18px;
  border-bottom: 1px solid #e5e5ef;
  padding-bottom: 15px;
}

.doughnut-wrapper {
  width: 400px;
  height: auto;
  margin: 0rem auto 0rem;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1080px) {
  .doughnut-wrapper {
    width: 100%;
    height: auto;
  }
}

@media screen and (max-width: 320px) {
  .doughnut-wrapper {
    width: 99%;
  }
}

@media screen and (max-width: 640px) {
  .doughnut-chart-grid-row {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 992px) {
  .doughnut-wrapper {
    margin: 1rem auto 1rem auto;
  }
}

/* doughnut chart js css ends here */

.groupedBar-chart {
  background-color: white;
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.1);
  border-radius: 7px;
  margin: 40px 0px 30px 0px;
  padding: 40px 30px 40px 30px;
}

.statistics-headline {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.statistics-headline {
  margin-bottom: 40px;
}

.statistics-headline > div > p:nth-child(1) {
  font-family: "Poppins_Regular";
  font-size: 18px;
  color: #9291a5;
}

.statistics-headline > div > p:nth-child(2) {
  font-family: "Poppins_SemiBold";
  font-size: 22px;
  color: #1f1f1f;
}

@media screen and (max-width: 576px) {
  .groupedBar-chart {
    display: none;
  }
}

.ind-d-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
}

@media screen and (max-width: 576px) {
  .ind-d-flex {
    flex-direction: column;
  }
}

.main-home-container {
  position: relative;
  top: 0;
  margin-top: 4.5rem;
}

.view-more-wrapper {
  text-align: center;
  margin-top: 2rem;
}

.view-more-users {
  background-color: #005e9e !important;
  font-family: "Poppins_SemiBold" !important;
  color: white !important;
  text-transform: capitalize !important;
  font-size: 16px;
  border-radius: 30px !important;
  height: 46px !important;
  width: 142px !important;
}

.grid-sidebar-row > div:nth-child(2) {
  position: relative;
  top: 0;
}

.comment-relative {
  position: relative;
  top: 0;
}

.comment-as-today-s {
  position: absolute;
  top: 10px;
  right: 10px;
  font-family: "Poppins_Medium_Italic";
  font-size: 12px;
  color: rgba(108, 108, 108, 1);
}

.show-l-data {
  position: absolute !important;
  top: -33px;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #005e9e !important;
  width: fit-content !important;
  margin: auto !important;
  border-radius: 0px 0px 4px 4px !important;
  cursor: pointer;
  text-transform: capitalize !important;
}

.latest-data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  padding: 4px 10px 4px 10px;
}

@media screen and (max-width: 1080px) {
  .ind-d-flex {
    padding-top: 15px;
  }
}

.date-time-updated {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.date-time-updated > p {
  font-size: 12px;
  color: #9291a5;
  font-family: "Poppins_Medium_Italic";
}

.react-daterange-picker__wrapper {
  background-color: rgba(240, 240, 240, 1);
  padding: 5px 20px;
  border-radius: 23px;
  border: none !important;
}

.react-daterange-picker__inputGroup__input {
  font-family: "Poppins_SemiBold" !important;
  font-size: 12px !important;
}

.react-calendar__month-view__weekdays {
  font-family: "Poppins_Medium" !important;
  color: white !important;
}

.react-calendar button {
  font-family: "Poppins_SemiBold" !important;
  font-size: 12px !important;
  color: white !important;
}

.react-daterange-picker__calendar .react-calendar {
  background-color: #005e9e !important;
}

.react-calendar__tile:disabled {
  background-color: black !important;
}

.react-calendar__navigation__arrow {
  font-size: 22px !important;
}

.react-calendar__tile--now {
  background: #9291a5 !important;
}

.react-daterange-picker__calendar {
  width: 250px !important;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #37b99c !important;
}

abbr[title] {
  border-bottom: none !important;
  cursor: inherit !important;
  text-decoration: none !important;
}

.react-calendar {
  border: none !important;
  border-radius: 5px !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus,
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #37b99c !important;
}

.react-daterange-picker__inputGroup {
  min-width: fit-content !important;
}

.react-calendar__navigation button:disabled {
  color: #000 !important;
  background-color: #c7c7c7;
}

.react-daterange-picker__inputGroup__input:invalid {
  background: transparent !important;
  border: none !important;
  outline: none !important;
}

@media screen and (max-width: 640px) {
  .ind-d-flex {
    flex-direction: column;
  }
  .date-time-updated {
    flex-direction: column;
  }
}
