.header-pk-management-flex-row,
.bags-into-col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.header-pk-management-flex-row > div > h1,
.bags-into-col > div > h1 {
  font-family: "Poppins_SemiBold";
  font-size: 30px;
  color: #1c1c1c;
}

.header-pk-management-flex-row > div > button,
.bags-into-col > div > button {
  background-color: #46cc8d !important;
  padding: 7px 20px;
  height: 38px;
  font-family: "Poppins_SemiBold";
  color: white;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  text-transform: capitalize;
}

@media screen and (max-width: 992px) {
  .bags-into-col {
    flex-direction: column;
  }
  .container-bags {
    margin-top: 20px;
  }
}

@media screen and (max-width: 640px) {
  .container-bags {
    grid-template-columns: repeat(3, 1fr) !important;
  }
  .container-bags > button {
    padding: 6px 25px !important;
  }
}

@media screen and (max-width: 576px) {
  .container-bags {
    grid-template-columns: repeat(2, 1fr) !important;
    white-space: nowrap;
  }
  .header-pk-management-flex-row {
    flex-direction: column;
  }

  .header-pk-management-flex-row > div > button {
    margin-top: 0.5rem;
  }

  .header-pk-management-flex-row > div > h1 {
    font-size: 26px;
  }
}

.dropdown-grid-row {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin-top: 1rem;
}

@media screen and (max-width: 992px) {
  .dropdown-grid-row {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .dropdown-grid-row {
    grid-template-columns: repeat(2, 1fr);
  }
}

.d-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.modal-box-agents {
  background-color: white;
  padding: 20px 20px 20px 20px;
  max-width: 400px;
  width: 400px;
  margin: auto;
  border-radius: 5px;
}

.border-sx {
  border: 1px solid #e5e5ef;
  margin-top: 1rem;
}

.person-arr-d-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px 15px 0px;
  border-bottom: 1px solid #e5e5ef;
  padding-bottom: 10px;
}

.person-arr-d-flex > div:nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.user-info-pick > p:nth-child(1) {
  font-family: "Poppins_SemiBold";
  color: #1c1c1c;
  font-size: 14px;
}

.user-info-pick > p:nth-child(2) {
  font-family: "Poppins_Regular";
  color: #5c5c5c;
  font-size: 12px;
}

.person-arr-d-flex:last-child {
  border-bottom: none;
}

.assingee {
  background-color: #5c5c5c !important;
  border: none;
  color: white !important;
  font-family: "Poppins_SemiBold" !important;
  padding: 5px 20px !important;
  border-radius: 15px !important;
  font-size: 10px !important;
  cursor: pointer;
  text-transform: capitalize !important;
}

@media screen and (max-width: 576px) {
  .modal-box-agents {
    margin: 0px 10px 0px 10px;
  }
}

/* .css-1o3br2j-control{
    min-height: 40px !important;
    border-radius: 23px !important;
    padding: 0px 10px 0px 10px;
}

.css-b62m3t-container{
    min-height: 40px !important;
    width: 120px !important;
    border-radius: 23px !important;
}

.css-1jqq78o-placeholder {
    color: #1C1C1C !important;
} */

@media screen and (max-width: 576px) {
  .picklist-header-limit-checkbox {
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
  }
}
