.go-back-row h1 {
  font-family: "Poppins_SemiBold";
  font-size: 30px;
}

@media screen and (max-width: 600px) {
  .go-back-row h1 {
    font-size: 20px;
  }
}

.go-back-row {
  text-decoration: none;
  color: #1c1c1c;
}

.header-flex-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.location-container-grid-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
}

.location-container-grid-row > div > input,
.location-container-grid-row > div > textarea {
  border-radius: 5px;
  height: 48px;
  background-color: #f0f0f0;
  border: none;
  width: 100%;
  outline: none;
  margin-top: 0.2rem;
  padding: 0px 10px 0px 10px;
  font-family: "Poppins_Bold";
  font-size: 15px;
}

.location-container-grid-row > div > label {
  font-family: "Poppins_SemiBold";
  color: #6c6c6c;
  font-size: 14px;
}

.location-display-wrapper {
  background-color: white;
  padding: 30px;
}

@media screen and (max-width: 576px) {
  .location-container-grid-row {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-inventory-row-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin-bottom: 20px;
}

.grid-inventory-row-container > div {
  padding: 20px;
  border-radius: 5px;
}

.grid-inventory-row-container > div > h3 {
  font-size: 18px;
  font-family: "Poppins_SemiBold";
}

.grid-inventory-row-container > div > p {
  font-size: 14px;
  font-family: "Poppins_SemiBold";
}

@media screen and (max-width: 640px) {
  .grid-inventory-row-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 576px) {
  .grid-inventory-row-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
