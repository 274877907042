.dropdown-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 0.25rem;
}

.dropdown-container > div {
  width: 20%;
}

@media screen and (max-width: 992px) {
  .dropdown-container > div {
    width: 60%;
  }
}

@media screen and (max-width: 598px) {
  .dropdown-container > div {
    width: 80%;
  }
}

/* Container for the tabs */
.tab-container {
  margin-top: 2rem;
  height: 100%;
  max-width: 20%;
  overflow: auto; /* border-gray-200 */
}

/* Navigation for the tabs */
.tab-nav {
  display: flex;
  gap: 0.25rem; /* gap-x-1 */
}

/* General styles for each tab button */
.tab-button {
  margin-bottom: -1px; /* -mb-px */
  padding: 0.75rem 1rem; /* py-3 px-4 */
  display: inline-flex;
  align-items: center;
  gap: 0.5rem; /* gap-x-2 */
  background-color: #a0a0a0; /* bg-gray-50 */
  font-size: 0.8rem; /* text-sm */
  font-weight: 500; /* font-medium */
  text-align: center;
  border: none;
  color: #ffffff; /* text-gray-500 */
  border-radius: 0.375rem 0.375rem 0 0; /* rounded-t-lg */
  cursor: pointer;
  transition: color 0.2s ease;
  font-family: "Poppins_Medium";
  font-size: 12px;
}

/* Hover and focus states */
.tab-button:hover,
.tab-button:focus {
  outline: none; /* focus:outline-none */
}

/* Disabled state */
.tab-button:disabled {
  opacity: 0.5; /* disabled:opacity-50 */
  pointer-events: none; /* disabled:pointer-events-none */
}

/* Active state (when the tab is selected) */
.tab-button.active {
  background-color: #005e9e; /* hs-tab-active:bg-white */
  border-bottom-color: transparent; /* hs-tab-active:border-b-transparent */
  color: white; /* hs-tab-active:text-blue-600 */
}

/* Content section for the tabs */
.tab-content {
  background-color: white;
  padding: 2rem;
  border: 1px solid #e5e7eb;
  width: 100%;
}

/* Text inside the tab content */
.tab-text {
  color: #6b7280; /* text-gray-500 */
}

.text-center {
  text-align: center;
}

/* Highlighted text (e.g., within an <em> tag) */
.tab-highlight {
  font-weight: 600; /* font-semibold */
  color: #1f2937; /* text-gray-800 */
}

/* Hide inactive tab content */
.hidden {
  display: none;
}

.submit-btn {
  margin: 1rem auto;
  border: none;
  border-radius: 3px;
  padding: 0.8rem 1.2rem;
  background-color: #46cc8d;
  color: #ffffff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  font-family: "Poppins_SemiBold";
  font-size: 13px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.submit-btn:focus {
  outline: none;
}

.submit-btn:disabled {
  opacity: 0.5 !important;
  pointer-events: none;
}

/* General container styles */
.grid-container {
  margin-top: 1.5rem; /* mt-6 */
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem; /* gap-4 */
}

/* Card styles */
.card {
  padding: 1rem; /* p-2 */
  border-radius: 0.375rem; /* rounded-md */
  color: #1c1c1c;
  font-family: "Poppins_Medium";
  /* Optional: add a background color */
}

.card1 {
  background-color: #9de4f2;
}
.card2 {
  background-color: #f2ae24;
}
.card3 {
  background-color: #f28d85;
}
.card4 {
  background-color: #f23079;
}
.card5 {
  background-color: #8fc8eb;
}

/* Title styles */
.title {
  font-size: 1.65rem; /* text-2xl */
  font-weight: 700; /* font-bold */
  font-family: "Poppins_Bold";
}

/* Description styles */
.description {
  margin-top: 0.25rem;
  font-size: 0.875rem; /* text-sm */
  font-weight: 500;
  font-family: "Poppins_Medium";
}

/* Responsive styles */
@media (min-width: 640px) {
  /* sm */
  .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  /* md */
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1024px) {
  /* lg */
  .grid-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

.table-container {
  margin-top: 2rem;
  padding: 2rem;
  background-color: white;
  border-radius: 0.5rem;
  border: 1px solid #e5e7eb;
}
