.tote-cta {
  text-align: center;
  margin-top: 1.5rem;
}

.tote-cta > button {
  background-color: #37b99c !important;
  height: 48px;
  width: 106px !important;
  border-radius: 2px !important;
  font-family: "Poppins_Bold";
  font-size: 14px;
  color: white !important;
}
