.pair-container {
  display: grid;
  grid-template-columns: 1fr auto 2fr;
  align-items: center;
  padding: 0.5rem 0;
  gap: 2rem;
}

@media (max-width: 1024px) {
  .pair-container {
    grid-template-columns: 1fr auto 1fr;
    gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  .pair-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    gap: 1rem;
  }
}

@media (max-width: 480px) {
  .pair-container {
    grid-template-columns: 1fr;
    gap: 0.5rem;
    padding: 0.25rem 0;
  }
}

.key {
  font-family: "Poppins_Medium";
  font-size: 0.875rem;
  font-weight: 500;
  color: #1c1c1c;
}

.value {
  font-weight: 600;
}
