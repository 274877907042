.randomBtn {
  margin-top: 2rem;
  border: none;
  padding: 0.65rem 1rem;
  border-radius: 20px;
  background-color: #916fdf;
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  font-family: "Poppins_medium";
}

.randomBtn:focus {
  outline: none;
}

@media screen and (max-width: 600px) {
  .randomBtn {
    margin: 0.25rem 0rem 1.5rem;
  }
}
