.header-cross-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5ef;
  padding-bottom: 15px;
}

.css-2ulfj5-MuiTypography-root {
  font-family: "Poppins_Bold" !important;
  font-size: 22px;
}

.bg-cross {
  background-color: #1c1c1c;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

@media screen and (max-width: 576px) {
  .MuiBox-root {
    width: 95% !important;
    margin: 20px 0px 20px 0px;
  }
  .modal-container {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1080px) {
  .css-zyiran {
    max-width: 95%;
  }
}

@media screen and (max-width: 576px) {
  .css-zyiran {
    height: 400px !important;
    overflow-y: scroll;
  }
}

.loading-text {
  font-family: "Poppins_SemiBold";
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
}

.dependent {
  background-color: transparent !important;
  width: 100%;
  border: 1px solid #e6e6e6 !important;
  margin-top: 5px;
  border-radius: 5px;
  outline: none;
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  padding: 10px;
}
