.user-icons-assign > .user-img-uri > img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  object-fit: cover;
  object-position: top;
  overflow-clip-margin: unset;
  overflow: hidden;
}

.user-img-uri {
  position: relative;
  top: 0;
}
.red {
  background-color: #ff0000;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.green {
  background-color: #46cc8d;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.red,
.green {
  position: absolute;
  bottom: 2px;
  right: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#users::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#users {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.search-bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: initial;
  position: relative;
  top: 0;
  margin-top: 15px;
  z-index: 9999;
  gap: 10px;
  margin-bottom: 15px;
}

.search-bar-container > div {
  position: relative;
  top: 0;
}

.search-bar-container > div > button {
  min-width: 20px !important;
  height: 30px !important;
  min-height: 20px !important;
  border-radius: 50px !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  padding: 6px 4px !important;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-border-radius: 50px !important;
  -moz-border-radius: 50px !important;
  -ms-border-radius: 50px !important;
  -o-border-radius: 50px !important;
}

.search-bar-container > div > input::placeholder {
  color: #697565;
  font-family: "Poppins_Medium";
  opacity: 1; /* Firefox */
}

.search-bar-container > div > input::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #697565;
}

.search-bar-container > div > input {
  width: 100%;
  height: 44px;
  outline: none;
  padding: 0px 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  border: 1px solid #f7f7f7;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.search-bar-container > button {
  background-color: #26a6fe !important;
  min-width: 40px !important;
  height: 40px !important;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.search-bar-container > button > span > svg {
  color: white;
  width: 0.8em !important;
  height: 0.8em !important;
}

.search-bar-container .wrapper {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  border: none !important;
}

.search-bar-container > div {
  flex: 1;
}

.search-bar-container .wrapper input {
  font-family: "Poppins_SemiBold";
  font-weight: 700;
}
