.active-index {
  background-color: #005e9e !important;
  color: white !important;
  width: 34px !important;
  height: 34px !important;
  padding: 0 !important;
  min-width: 34px !important;
  margin-right: 10px !important;
  border-radius: 4px !important;
  font-family: "Poppins_Bold" !important;
  font-size: 16px !important;
}

.page-numbers {
  width: 34px !important;
  height: 34px !important;
  padding: 0 !important;
  min-width: 34px !important;
  margin-right: 10px !important;
  border-radius: 4px !important;
  font-family: "Poppins_Bold" !important;
  font-size: 16px !important;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.prev_page,
.next_page {
  width: 34px !important;
  height: 34px !important;
  padding: 0 !important;
  min-width: 34px !important;
  margin-right: 10px !important;
  border-radius: 4px !important;
  background-color: #1c1c1c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  border: none;
}

.prev_page:disabled {
  opacity: 0.8;
  pointer-events: none;
}

.next_page:disabled {
  opacity: 0.8;
  pointer-events: none;
}
