.cancel-picklist-head {
  font-size: 18px;
  padding: 0px 30px 0px 30px;
  color: #1c1c1c;
  font-family: "Poppins_SemiBold";
  text-align: center;
  margin-top: 10px;
}

.flex-cancel-cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  gap: 20px;
}

.flex-cancel-cta>button{
    width: 140px;
    border-radius: 2px !important;
    height: 48px;
    font-family: 'Poppins_SemiBold';
    font-size: 16px;
    color: white;
}

.flex-cancel-cta > button:nth-child(2) {
  background-color: rgba(234, 84, 85, 1) !important;
}

.flex-cancel-cta > button:nth-child(1) {
  background-color: rgba(98, 222, 155, 1) !important;
}
