.wrapper-user-grid-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;
  margin-top: 2rem;
}

.user-img {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: -10px auto 20px auto;
  text-align: center;
}

.user-details {
  padding: 0px 20px 0px 20px;
  display: grid;
  grid-template-columns: 2fr 0.3fr 3fr;
  gap: 10px;
}

.user {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  background-color: white;
  padding-bottom: 20px;
}

.user > a {
  text-decoration: none;
}

.user-details > div > p {
  margin-bottom: 0.5rem;
  font-family: "Poppins_SemiBold";
  font-size: 12px;
  color: #1c1c1c;
  /* white-space: nowrap; */
}

.user-details > div:nth-child(3) > p {
  font-family: "Poppins_Bold" !important;
}

.unique-color {
  color: #46cc8d !important;
}

.tag {
  background: linear-gradient(90deg, #46cc8d 100%, #10aa62 100%);
  position: relative;
  top: 20px;
  padding: 5px 30px 5px 10px;
  display: inline-block;
}

.no_tag {
  background: linear-gradient(90deg, #46cc8d 100%, red 100%);
  position: relative;
  top: 20px;
  padding: 5px 30px 5px 10px;
  display: inline-block;
}

.tag > p {
  font-family: "Poppins_SemiBold";
  font-size: 12px;
  color: white;
}

.no_tag > p {
  font-family: "Poppins_SemiBold";
  font-size: 12px;
  color: white;
}

.triangle {
  position: absolute;
  top: 5px;
  transform: rotate(270deg);
  border-color: transparent transparent white transparent;
  border-style: solid;
  border-width: 0px 18px 18px 18px;
  height: 0px;
  right: -10px;
  width: 0px;
}

.action-btn {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0px 20px 20px 20px;
  gap: 20px;
}

.action-btn > button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7px 10px 7px 10px;
  white-space: nowrap;
  border-radius: 8px;
  text-transform: capitalize;
}

.action-btn > button:nth-child(1) {
  background-color: #46cc8d;
}

.action-btn > button > span {
  flex-direction: column;
}

.action-btn > button > span > p {
  margin-top: 5px;
  font-size: 12px;
  color: white;
  font-family: "Poppins_SemiBold" !important;
}

.action-btn > button:nth-child(2) {
  background-color: #26a6fe;
}

@media screen and (max-width: 992px) {
  .wrapper-user-grid-row {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 640px) {
  .user-img {
    margin: 30px 0px 20px 0px;
  }
}

@media screen and (max-width: 576px) {
  .wrapper-user-grid-row {
    grid-template-columns: repeat(1, 1fr);
  }
  .user-img {
    margin: -10px 0px 20px 0px;
  }
}

.user-img > img {
  width: 102px;
  height: 102px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  overflow-clip-margin: unset;
  overflow: hidden;
}

.img-default {
  width: 102px;
  background: rgb(38, 166, 254);
  background: radial-gradient(
    circle,
    rgba(38, 166, 254, 0.8883928571428571) 0%,
    rgba(38, 166, 254, 1) 35%
  );
  height: 102px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.img-default>p{
  font-family: 'Poppins_SemiBold';
  font-size: 28px;
  color: white;
  text-transform: uppercase;
}

.message-top {
  margin-top: 20px;
}
