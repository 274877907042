.date_handover > input {
  border: none;
  min-height: 48px;
  border-radius: 4px;
  padding: 2px 8px;
  font-family: Poppins_SemiBold;
  font-size: 12px;
  outline: none;
  background-color: #e0e0e0;
  width: 100%;
}
