.wrapper-picker-container > h1 {
  font-family: "Poppins_SemiBold";
  font-size: 30px;
  color: #1c1c1c;
  margin-bottom: 1rem;
}

.input-actions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  margin-bottom: 1rem;
}

.input-actions > div:nth-child(1) {
  position: relative;
  top: 0;
}

.input-actions > div:nth-child(1) > img {
  position: absolute;
  top: 12px;
  left: 8px;
  width: 20px;
}

.input-actions > div:nth-child(1) > input {
  width: 100%;
  min-height: 48px;
  border-radius: 4px;
  outline: none;
  padding: 2px 35px;
  font-family: "Poppins_Regular";
  font-size: 14px;
  border: none;
  background-color: #e0e0e0;
}

.css-1o3br2j-control {
  min-height: 48px !important;
  /* border-radius: 23px !important; */
  padding: 0px 10px 0px 10px;
}

.css-b62m3t-container {
  min-height: 48px !important;
  /* width: 120px !important; */
  /* border-radius: 23px !important; */
}

.css-1jqq78o-placeholder {
  color: #1c1c1c !important;
  /* font-family: 'Poppins'; */
}

.css-tj5bde-Svg {
  fill: #1c1c1c !important;
}

.css-1u9des2-indicatorSeparator {
  width: 0px !important;
}

@media screen and (max-width: 576px) {
  .input-actions {
    grid-template-columns: repeat(2, 1fr);
  }

  .wrapper-picker-container > h1 {
    font-size: 26px;
  }
}

.rendering-pagination {
  text-align: center;
  margin-top: 20px;
}

.result-out {
  color: #ff0000;
  font-family: "Poppins_SemiBold";
  font-size: 15px;
}

.result-out-only-out {
  color: #ff0000;
  font-family: "Poppins_SemiBold";
  font-size: 15px;
  text-align: end;
  margin-bottom: 20px;
}


.clear-filter>button{
    background-color: rgba(38, 166, 254, 1) !important;
    height: 48px;
    border-radius: 4px !important;
    color: white;
    font-family: 'Poppins_SemiBold';
    font-size: 14px;
    text-transform: capitalize !important;
    padding: 0px 20px 0px 20px;
}