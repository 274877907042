@import url("https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@1,700&display=swap");

.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.not-found-container > img {
  width: 50%;
  mix-blend-mode: darken;
}

.not-found-header {
  font-size: 42px;
  margin-top: -4rem;
  font-family: "EB Garamond", serif;
}

@media screen and (max-width: 1080px) {
  .not-found-container > img {
    width: 100%;
  }
}
@media screen and (max-width: 576px) {
  .not-found-header {
    font-size: 28px;
    margin-top: -2rem;
  }
}
