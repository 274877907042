* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
  -moz-transition: 0.3s ease-in-out;
  -ms-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
}

body,
html {
  overflow-x: hidden;
  background-color: #f7f7f9a8;
  font-family: "Poppins";
}

/* scrollbar narrow css  */
*::-webkit-scrollbar {
  width: 7px;
  height: 5px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

*::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 50px;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap");

/* importing fonts */
@font-face {
  font-family: Poppins_Medium;
  src: url("./Assets/Fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins_Regular;
  src: url("./Assets/Fonts/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins_SemiBold;
  src: url("./Assets/Fonts/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: Poppins_Bold;
  src: url("./Assets/Fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins_Bold;
  src: url("./Assets/Fonts/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins_Medium_Italic;
  src: url("./Assets/Fonts/Poppins-MediumItalic.ttf");
}

#__react-alert__ > div > div > div {
  width: fit-content !important;
  text-transform: capitalize !important;
  font-family: "Poppins_Medium" !important;
}

.css-79ws1d-MuiModal-root {
  overflow: auto !important;
}

.css-1n7v3ny {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.filter-dropdown-cross {
  position: relative;
  top: 0;
}

.filter-icon {
  cursor: pointer;
  position: absolute;
  top: 55%;
  right: 44px;
  border: none;
  background-color: transparent;
  transform: translateY(-50%);
}

textarea {
  resize: none;
}

@media screen and (max-width: 992px) {
  .settings-grid-row {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

.settings-cta-container > button {
  background-color: #46cc8d !important;
  color: white;
  margin-top: 20px;
  width: 100px;
  height: 44px;
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  border-radius: 2px !important;
  -webkit-border-radius: 2px !important;
  -moz-border-radius: 2px !important;
  -ms-border-radius: 2px !important;
  -o-border-radius: 2px !important;
}

.settings-grid-row > div > div {
  margin-top: 7px !important;
}

.css-qfsyed-indicatorContainer {
  display: none !important;
}

/* Hide the vertical scrollbar */
.MuiTableContainer-root::-webkit-scrollbar {
  width: 0;
}

/* For Firefox */
.MuiTableContainer-root {
  overflow-x: unset !important;
  overflow-y: visible !important;
  scrollbar-width: none;
}

.css-8ndowl {
  overflow: hidden !important;
}

.sku-heading {
  font-size: 18px;
  font-family: "Poppins_Medium";
  /* margin-top: 20px; */
  margin-bottom: 20px;
}

.sku-headings {
  font-size: 20px;
  font-family: "Poppins_Medium";
  margin-bottom: 20px;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name,
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-family: "Poppins_SemiBold" !important;
  font-size: 12px !important;
}

.react-datepicker__header {
  border-bottom: 1px solid #f7f7f7 !important;
}

.react-datepicker {
  border: 1px solid #ddd !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  stroke: #c7c7c7 !important;
}

.awssld__bullets button {
  width: 8px !important;
  height: 8px !important;
  background-color: #46cc8d !important;
}

/* Sub Location Row css starts here */
.sub-location-row {
  background-color: white;
  margin-top: 1rem;
  border: 1px solid #e6e6e6;
  padding: 30px 20px 30px 20px;
  border-radius: 5px;
  margin-bottom: 1.5rem;
}

.input-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.input-grid-container > div > label {
  color: rgba(108, 108, 108, 1);
  font-size: 14px;
  font-family: "Poppins_Medium";
  font-size: 14px;
}

.input-grid-container > div > input {
  width: 100%;
  height: 48px;
  border: 1px solid rgba(230, 230, 230, 1);
  margin-top: 5px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  outline: none;
  padding: 0px 10px;
  font-family: "Poppins_SemiBold";
}

.sub-location-grid-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 30px;
}

.sub-location-grid-row > div {
  padding: 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.sub-location-grid-row > div > h2 {
  font-family: "Poppins_Bold";
  font-size: 28px;
  color: rgba(28, 28, 28, 1);
}

.sub-location-grid-row > div > p {
  font-size: 14px;
  color: rgba(28, 28, 28, 1);
  font-family: "Poppins_Medium";
  margin-top: 2px;
}

.CTA-complete-audit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.CTA-complete-audit > button {
  background-color: #46cc8d !important;
  color: white;
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  height: 48px;
  width: 176px;
  text-transform: capitalize !important;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

@media screen and (max-width: 640px) {
  .input-grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 598px) {
  .sub-location-grid-row {
    grid-template-columns: repeat(1, 1fr);
  }
  .input-grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
/* Sub Location Row css ends here */

.scanning-loading,
.scanning-loading > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: fit-content;
  gap: 10px;
}

.scanning-loading > div > p {
  font-family: "Poppins_SemiBold";
  font-size: 14px;
  color: #26a6fe;
}

.mt-2 {
  margin-top: 8px !important;
  margin-bottom: 0px !important;
}

.mt-x {
  margin-top: 1rem !important;
}

.bypass-all {
  margin-right: 10px !important;
  width: 100px !important;
}

.sync-lost-cta {
  width: 140px !important;
}

.mt-5x {
  margin-top: 30px;
  margin-bottom: 10px;
}

/* CSV FILE */
.browser-file-csv {
  background: #26a6fe;
  display: table;
  color: #fff;
  /* margin-top: 30px; */
  font-family: "Poppins_SemiBold";
  padding: 7px 30px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.browser-file-csv > input[type="file"] {
  display: none;
}

.mt__4 {
  margin-top: 30px;
}

.mt__x1 {
  margin-top: 10px;
  background-color: transparent !important;
  border: 1px solid #e6e6e6 !important;
  font-size: 14px !important;
  font-family: "Poppins_SemiBold" !important;
}

.csv-export-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.csv-export-row > div > button {
  background-color: #46cc8d !important;
  display: table;
  color: #fff;
  /* margin-top: 30px; */
  font-family: "Poppins_SemiBold";
  padding: 7px 30px;
  border-radius: 2px;
}

@media screen and (max-width: 330px) {
  .csv-export-row {
    flex-direction: column;
    gap: 0px;
  }
}

.img-col-row {
  text-decoration: none;
  color: rgba(28, 28, 28, 1);
  font-size: 12px;
}

.picking-after-lost-request {
  color: #26a6fe;
  text-decoration: underline;
}

.repeat-icon {
  margin: 0px 5px !important;
}

.bg-repeat {
  background-color: rgba(70, 204, 141, 1) !important;
  min-width: 34px !important;
  width: 34px !important;
  height: 34px !important;
  border-radius: 7px !important;
}

.count__request_user {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  align-items: center;
  gap: 10px;
}

.count__request_user > img {
  width: 42px;
  height: 42px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.count__request_user > p {
  color: #1c1c1c;
}

.picking-after-lost-request {
  cursor: pointer;
}

.generate-bulk-label {
  background-color: #46cc8d !important;
  text-transform: capitalize !important;
  color: white !important;
  font-size: 12px !important;
  font-family: "Poppins_SemiBold" !important;
  padding: 6px 10px !important;
  border-radius: 20px !important;
  height: 24px;
}

.create__dock__container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.create__dock__container > button {
  margin-top: 15px;
  background-color: #46cc8d !important;
  width: 160px;
  height: 44px;
  font-family: "Poppins_SemiBold";
  font-size: 14px;
}

.create__dock__container > input {
  width: 100%;
  height: 48px;
  border: none;
  outline: none;
  font-family: "Poppins_SemiBold";
  font-size: 12px;
  background-color: #e6e6e6;
  padding: 0px 10px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
