.delete-tote-message > p {
  font-size: 14px;
  color: #1c1c1c;
  font-family: "Poppins_Regular";
  text-align: center;
  margin-top: 20px;
}

.delete-tote-message {
  text-align: center;
}

.delete-tote-message > button {
  margin-top: 20px;
  margin-left: 10px;
  height: 48px;
  width: 106px;
  font-family: "Poppins_Bold";
  color: white;
  font-size: 14px;
  border-radius: 2px !important;
}

.delete-tote-message > button:nth-child(2) {
  background-color: #ff0000;
}

.delete-tote-message > button:nth-child(3) {
  background-color: #37b99c;
}

.message {
  margin-top: 15px;
  text-align: center;
}

.message > h3 {
  font-size: 20px;
  color: #1c1c1c;
  text-transform: uppercase;
  font-family: "Poppins_SemiBold";
}

.message > p {
  font-size: 14px;
  font-family: "Poppins_Regular";
  margin-top: 5px;
}
